import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const InputFile = ({ register, id, fileName, ...props }) => {
  const [fileText, setFileText] = useState(fileName);
  const { onChange, name, onBlur, ref } = register(props.name);

  function handleOnChange(e) {
    setFileText(e.target.value.split('\\')[2]);
    onChange(e);
  }

  return (
    <>
      <label
        className={`${styles.label} ${props.disabled ? styles['label--disabled'] : ''}`}
        htmlFor={id}
      >
        {fileText}
      </label>
      <input
        className={styles.inputFile}
        type="file"
        name={name}
        id={id}
        onBlur={onBlur}
        onChange={handleOnChange}
        ref={ref}
        {...props}
      />
    </>
  );
};

InputFile.displayName = 'InputFile';

InputFile.propTypes = {
  id: PropTypes.string,
  register: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  fileName: PropTypes.string,
};

export default InputFile;
