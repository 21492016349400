import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import logo from 'assets/icons/logo-apd.svg';
import AddressList from 'UI/molecules/AddressList';
import SocialNetworks from 'UI/molecules/SocialNetworksList';
import LinksList from 'UI/molecules/LinksList';
import Icon from 'UI/atoms/Icon';

import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <Grid container className={styles.line1}>
          <Grid item xs={12} md={12} lg={2} className={styles.logo}>
            <Icon src={logo} className={styles.logo} alt="APD logo" />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <AddressList addresses={t('addresses')} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} className={styles.line2}>
            <p className={styles.copyright}>{t('reserved.rights')}</p>
            <LinksList links={t('links')} />
            <SocialNetworks networks={t('networks')} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
