import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CityField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_city">
        {t('form.step3.city.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.city}
          id="form_city"
          name="city"
          ref={ref}
          {...other}
          placeholder={t('form.step3.city.placeholder')}
        />
        {errors.city && <Form.Error>{errors.city?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

CityField.displayName = 'CityField';

CityField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CityField;
