import CompanyDataCard from 'components/CompanyDataCard';

const Step3 = () => {
  return (
    <>
      <CompanyDataCard />
    </>
  );
};

export default Step3;
