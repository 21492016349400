import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const StepContainer = (props) => {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
};

StepContainer.propTypes = {
  children: PropTypes.node,
};

export default StepContainer;
