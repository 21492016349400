import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isValidIBAN } from 'ibantools';

const validationSchema = (checkProvinceRequired, getPostalCodePrefix) => {
  const { t } = useTranslation(['form', 'companyDataCard']);

  return Yup.object().shape({
    company_name: Yup.string().required(t('error.required')),
    cif: Yup.string().required(t('error.required')),
    country: Yup.object().shape({
      label: Yup.string().required(t('error.required')),
      id: Yup.string().required(t('error.required')),
    }),
    address: Yup.string().required(t('error.required')),
    province: checkProvinceRequired()
      ? Yup.object()
          .shape({
            label: Yup.string().required(t('error.required')),
            id: Yup.string().required(t('error.required')),
          })
          .required(t('error.required'))
      : Yup.object().nullable(true),
    city: Yup.string().required(t('error.required')),
    postal_code: Yup.string()
      .test(
        'validPostalCode',
        t('error.postalCode.invalid', { ns: 'form' }),
        (val) => {
          const currentPostalCodePrefix = getPostalCodePrefix();
          return val.startsWith(currentPostalCodePrefix);
        },
      )
      .required(t('error.required')),
    iban: Yup.string()
      .test('validCode', t('error.iban.invalid', { ns: 'form' }), (val) => {
        return isValidIBAN(val);
      })
      .required(t('error.required')),
  });
};

export default validationSchema;
