import { PropTypes } from 'prop-types';
import { createContext, useReducer } from 'react';

import initialState from './initialState';
import reducer from './reducer';

export const AppContext = createContext();

const { Provider } = AppContext;

const AppProvider = (props) => {
  const { value } = props;
  const [state, dispatch] = useReducer(reducer, value || initialState);

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
};

export default AppProvider;
