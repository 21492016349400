import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const CTASubtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
);

CTASubtitle.propTypes = {
  children: PropTypes.node,
};

export default CTASubtitle;
