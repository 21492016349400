import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CheckCommunicationsAcceptanceField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Control>
        <Form.Checkbox
          htmlFor="form_communication_acceptance"
          id="form_communication_acceptance"
          name="communication_acceptance"
          ref={ref}
          {...other}
        >
          {t('form.step2.communication_acceptance.label')}
        </Form.Checkbox>
      </Form.Control>
    </Form.Field>
  );
});

CheckCommunicationsAcceptanceField.displayName =
  'CheckCommunicationsAcceptanceField';

CheckCommunicationsAcceptanceField.propTypes = {
  errors: PropTypes.object,
};

export default CheckCommunicationsAcceptanceField;
