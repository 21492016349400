import PasswordChecklist from 'react-password-checklist';

import './checklist.scss';

const PasswordCheck = (props) => (
  <PasswordChecklist className="passwordChecklist" {...props} />
);

PasswordCheck.displayName = 'PasswordCheck';

export default PasswordCheck;
