import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const EmailField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_email">
        {t('form.step2.email.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          disabled
          id="form_email"
          name="email"
          ref={ref}
          {...other}
          placeholder={t('form.step2.email.placeholder')}
        />
      </Form.Control>
    </Form.Field>
  );
});

EmailField.displayName = 'EmailField';

export default EmailField;
