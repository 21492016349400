import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const validationSchema = () => {
  const { t } = useTranslation(['form']);

  return Yup.object().shape({
    sector: Yup.string()
      .test('len', t('error.required', { ns: 'form' }), (val) => val.length > 0)
      .required(t('error.required', { ns: 'form' })),
  });
};

export default validationSchema;
