import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const SectionTitle = ({ children }) => (
  <p className={styles.title}>
    <span>{children}</span>
  </p>
);

SectionTitle.propTypes = {
  children: PropTypes.node,
};

export default SectionTitle;
