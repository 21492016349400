import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CountryField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_country">
        {t('form.step3.country.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Autocomplete
          id="form_country"
          error={errors.country}
          ref={ref}
          placeholder={t('form.step3.country.placeholder')}
          {...other}
        />
        {errors.country && <Form.Error>{errors.country?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

CountryField.displayName = 'CountryField';

CountryField.propTypes = {
  errors: PropTypes.object,
};

export default CountryField;
