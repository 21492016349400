import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const FeedbackText = ({ children }) => (
  <div className={styles.text}>{children}</div>
);

FeedbackText.propTypes = {
  children: PropTypes.node,
};

export default FeedbackText;
