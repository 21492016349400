import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const ProvinceField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_province">
        {t('form.step2.province.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Autocomplete
          id="form_province"
          {...other}
          ref={ref}
          error={errors.province}
          placeholder={t('form.step2.province.placeholder')}
        />
        {errors.province && <Form.Error>{errors.province?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

ProvinceField.displayName = 'ProvinceField';

ProvinceField.propTypes = {
  errors: PropTypes.object,
};

export default ProvinceField;
