import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const PasswordCheckField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { ...other } = props;

  return (
    <Form.PasswordCheck
      rules={['minLength', 'number', 'capital', 'match']}
      minLength={7}
      iconSize={10}
      ref={ref}
      className="passwordChecklist"
      messages={{
        minLength: t('form.step2.password.minLength'),
        number: t('form.step2.password.number'),
        capital: t('form.step2.password.capital'),
        match: t('form.step2.password.match'),
      }}
      onChange={(isValid) => {}}
      {...other}
    />
  );
});

PasswordCheckField.displayName = 'PasswordCheckField';

export default PasswordCheckField;
