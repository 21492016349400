import PropTypes from 'prop-types';

import Icon from 'UI/atoms/Icon';

import styles from './styles.module.scss';

const SocialNetworksList = ({ networks }) => {
  if (networks.length === 0) return '';

  return (
    <ul className={styles.list}>
      {networks.map((item) => (
        <li className={styles.item} key={item.name}>
          <a href={item.link} target="_blank" rel="noreferrer">
            <Icon src={item.icon} width="30px" height="30px"></Icon>
          </a>
        </li>
      ))}
    </ul>
  );
};

SocialNetworksList.propTypes = {
  networks: PropTypes.array,
};

export default SocialNetworksList;
