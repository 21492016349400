import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CardTitle = ({ children }) => (
  <span className={styles.title}>{children}</span>
);

CardTitle.propTypes = {
  children: PropTypes.node,
};

export default CardTitle;
