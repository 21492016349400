import UploadDocumentCard from 'components/UploadDocumentCard';

const Step5 = () => {
  return (
    <>
      <UploadDocumentCard />
    </>
  );
};

export default Step5;
