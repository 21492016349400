import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CompanyNameField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_company_name">
        {t('form.step3.companyName.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.companyName}
          id="form_company_name"
          name="company_name"
          ref={ref}
          {...other}
          placeholder={t('form.step3.companyName.placeholder')}
        />
        {errors.companyName && (
          <Form.Error>{errors.companyName?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

CompanyNameField.displayName = 'CompanyNameField';

CompanyNameField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CompanyNameField;
