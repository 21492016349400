import UserValidationCard from 'components/UserValidationCard';

const Step7 = () => {
  return (
    <>
      <UserValidationCard />
    </>
  );
};

export default Step7;
