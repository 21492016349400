import axios from 'axios';

export default async function uploadDocument(userId, file) {
  const requestBody = {
    file,
    user_id: userId,
  };

  try {
    const instanceAxios = axios.create();
    instanceAxios.interceptors.request.clear();

    instanceAxios.interceptors.request.use(function (config) {
      config.headers['Content-Type'] = 'multipart/form-data';
      return config;
    });

    const { data } = await instanceAxios.post(`/upload-document`, requestBody);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
