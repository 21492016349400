import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const PhoneField = forwardRef((props, ref) => {
  const { errors, disabled, ...other } = props;

  const { t } = useTranslation('userDataCard');

  return (
    <Form.Field>
      <Form.Label htmlFor="form_phone">
        {t('form.step2.phone.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Phone
          {...other}
          error={errors.phone}
          ref={ref}
          inputProps={{
            id: 'form_phone',
            name: 'phone',
            disabled,
          }}
        />
        {errors.phone && <Form.Error>{errors.phone?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

PhoneField.displayName = 'PhoneField';

PhoneField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default PhoneField;
