import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Button = (props) => {
  const { disabled, type, style, children, ...other } = props;
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${styles.button} ${styles[`button--${style}`]}`}
      {...other}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  type: 'submit',
  style: 'green',
};

Button.propTypes = {
  style: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
