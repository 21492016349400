import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Error from './error';
import Step7 from './step7';

const routes = [
  {
    path: '/:locale/',
    index: true,
    element: <Step1 />,
  },
  {
    path: '/:locale/user-data',
    index: true,
    element: <Step2 />,
  },
  {
    path: '/:locale/awaiting-validation',
    index: true,
    element: <Step3 />,
  },
  {
    path: '/:locale/company-data',
    index: true,
    element: <Step4 />,
  },
  {
    path: '/:locale/upload',
    index: true,
    element: <Step5 />,
  },
  {
    path: '/:locale/register-finished',
    index: true,
    element: <Step6 />,
  },
  {
    path: '/:locale/validation',
    index: true,
    element: <Step7 />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
