import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Address = ({ address }) => (
  <>
    <p className={styles.department}>{address.department}</p>
    {address.address && (
      <p className={styles.data}>
        <span dangerouslySetInnerHTML={{ __html: address.address }}></span>
      </p>
    )}
    {address.mail && (
      <p className={styles.data}>
        <a className={styles.mail} href={`mailto:${address.mail}`}>
          {address.mail}
        </a>
      </p>
    )}
    {address.phone && <p className={styles.data}>{address.phone}</p>}
  </>
);

Address.propTypes = {
  address: PropTypes.object,
};

export default Address;
