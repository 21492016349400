import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const ArrowLink = (props) => {
  const { children, arrow, ...other } = props;
  return (
    <p className={styles.paragraph}>
      <a className={styles.link} {...other}>
        <i className={`${styles.arrow} ${styles[`arrow--${arrow}`]}`}></i>
        {children}
      </a>
    </p>
  );
};

ArrowLink.propTypes = {
  arrow: PropTypes.string,
  children: PropTypes.node,
};

export default ArrowLink;
