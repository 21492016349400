import axios from 'axios';

import toCamelCase from 'utils/toCamelCase';

export const getProvinces = async (zone) => {
  const { data } = await axios.get('/provinces', { params: { zone_id: zone } });

  return data
    .map((province) => ({
      id: province.id,
      name: province.name,
      postal_code: province.postal_code,
    }))
    .sort((a, b) =>
      toCamelCase(a.name.toLowerCase()).localeCompare(
        toCamelCase(b.name.toLowerCase()),
      ),
    );
};
