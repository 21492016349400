import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const AddressField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_address">
        {t('form.step3.address.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.address}
          id="form_address"
          name="address"
          ref={ref}
          {...other}
          placeholder={t('form.step3.address.placeholder')}
        />
        {errors.address && <Form.Error>{errors.address?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

AddressField.displayName = 'AddressField';

AddressField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AddressField;
