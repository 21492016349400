import * as Yup from 'yup';
import { isValid, getYear, getMonth, subYears, compareAsc } from 'date-fns';

import { validateId } from 'services/validateId';
import { useTranslation } from 'react-i18next';

const validationSchema = (zone, countryIdentifier, setCheckingId) => {
  const { t } = useTranslation(['userDataCard', 'form']);

  const regex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðºª ,.'-]*$/u;

  return Yup.object().shape(
    {
      name: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .test('validation', t('error.invalid_name', { ns: 'form' }), (val) => {
          return regex.test(val);
        }),
      surname1: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .test('validation', t('error.invalid_name', { ns: 'form' }), (val) => {
          return regex.test(val);
        }),
      surname2: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .test('validation', t('error.invalid_name', { ns: 'form' }), (val) => {
          return regex.test(val);
        }),
      legal_identifier: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .test(
          'is-identifier-valid',
          t('legal_identifier.invalid', { ns: 'form' }),
          async (value) => {
            if (!value) return true;
            setCheckingId(true);
            const result = await validateId({
              id: value,
              country_code: countryIdentifier,
            });
            setCheckingId(false);
            const validTypes = [
              'NIF',
              'NIE',
              'Documento',
              'NIT',
              'DNI',
              'C.C./B.I.',
              'C.C.',
              'CIN',
              'ICE',
              'RUC',
            ];
            return result.valid && validTypes.includes(result.type);
          },
        ),
      phone: Yup.string()
        .test('len', t('error.required', { ns: 'form' }), (val, context) => {
          const dialCode = context.parent?.dialCode;
          if (!dialCode) return false;
          return val.slice(dialCode.length).length > 0;
        })
        .test('numbers', t('phone.invalid', { ns: 'form' }), (val, context) => {
          if (!val) return true;
          if (zone.validPhoneNumbers.length === 0) return true;
          const dialCodeInfo = context.parent?.dialCode;
          const number = val.slice(dialCodeInfo.dialCode.length);
          return zone.validPhoneNumbers.includes(parseInt(number[0], 10));
        }),
      birthDate: Yup.date()
        .test('validation', t('error.invalid_date', { ns: 'form' }), (val) => {
          if (val === '') return false;
          if (!isValid(val)) return false;
          const currentDate = new Date();
          const referenceDate = new Date(
            getYear(currentDate),
            getMonth(currentDate),
            currentDate.getDate(),
          );
          const maxDate = subYears(referenceDate, 50);
          const minDate = subYears(referenceDate, 90);

          if (compareAsc(val, maxDate) === 1) {
            return false;
          }
          if (compareAsc(val, minDate) === -1) {
            return false;
          }
          return true;
        })
        .required(t('error.required', { ns: 'form' })),
      province: zone.provinceRequired
        ? Yup.string().required(t('error.required', { ns: 'form' }))
        : Yup.string(),
      department: Yup.string()
        .test(
          'len',
          t('error.required', { ns: 'form' }),
          (val) => val.length > 0,
        )
        .required(t('error.required', { ns: 'form' })),
      workPosition: Yup.string()
        .test(
          'len',
          t('error.required', { ns: 'form' }),
          (val) => val.length > 0,
        )
        .required(t('error.required', { ns: 'form' })),
      otherWorkPosition: Yup.string()
        .ensure()
        .when('workPosition', {
          is: '-1',
          then: () =>
            Yup.string()
              .test(
                'len',
                t('error.required', { ns: 'form' }),
                (val) => val.length > 0,
              )
              .required(t('error.required', { ns: 'form' })),
        }),
      password: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .test(
          'len',
          t('error.invalid', { ns: 'form' }),
          (val) => val.length > 7,
        )
        .test('numbers', t('error.invalid', { ns: 'form' }), (val) => {
          return /\d/.test(val);
        })
        .test('uppercase', t('error.invalid', { ns: 'form' }), (val) => {
          return /[A-Z]/.test(val);
        }),
      password_confirmation: Yup.string()
        .required(t('error.required', { ns: 'form' }))
        .oneOf([Yup.ref('password'), null]),
      conditions: Yup.boolean().oneOf(
        [true],
        t('error.required', { ns: 'form' }),
      ),
    },
    ['workPosition', 'otherWorkPosition'],
  );
};

export default validationSchema;
