import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import config from 'data/config.json';
import { useAppStore } from 'context/AppProvider/hooks';
import { associateCompany } from 'services/associateCompany';
import { validateBulletin } from 'services/validateBulletin';
import Notice from 'UI/atoms/Notice';
import Form from 'UI/molecules/Form';
import Card from 'UI/molecules/Card';
import CardHeader from 'UI/atoms/CardHeader';
import Banner from 'UI/atoms/Banner';
import StepContainer from 'UI/atoms/StepContainer';
import HelpCTA from 'components/HelpCTA';
import { getProvinces } from 'services/getProvinces';
import { getCountries } from 'services/getCountries';
import FeedbackText from 'UI/atoms/FeedbackText';
import PriceBlock from 'UI/atoms/PriceBlock';

import CompanyDataFields from './fields';
import validationSchema from './validationSchema';

const CompanyDataCard = () => {
  const { locale } = useParams();
  const { t } = useTranslation('companyDataCard');
  const [appState] = useAppStore();
  const [countries, setCountries] = useState([]);
  const [loadingProvinces, setLoadingProvinces] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [provinceRequired, setProvinceRequired] = useState(false);
  const [postalCodePrefix, setPostalCodePrefix] = useState(null);
  const [loading, setLoading] = useState(false);
  const baseUrl = `${window.location.protocol}//${window.location.host}/${locale}/upload`;
  const navigate = useNavigate();

  const schema = validationSchema(
    () => provinceRequired,
    () => postalCodePrefix,
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: appState.data.email,
      zone_id: appState.data.zone_id,
      company_name: appState.data.company_name,
      country_identifier: appState.data.country_identifier,
      lead_id: appState.data.lead_id,
      cif: appState.data.cif,
      phone: appState.data.phone,
      country: null,
      province: null,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const country = watch('country');
  const province = watch('province');
  const iban = watch('iban');

  const onSubmit = async (data) => {
    setLoading(true);

    const companyData = {
      user_email: data.email,
      zone_id: data.zone_id,
      name: data.company_name,
      legal_identifier: data.cif,
      email: data.email,
      legal_identifier_country_code: data.country_identifier,
      country: data.country.id,
      autonomous_community: data.province.id,
      postal_code: data.postal_code,
      address: data.address,
      phone: data.phone,
      city: data.city,
      origin: 'BADGenius',
      lead_id: data.lead_id,
    };

    try {
      await associateCompany(companyData);
    } catch (e) {
      setLoading(false);
      return false;
    }

    const validateBulletinData = {
      lead_id: data.lead_id,
      company_id: appState.data.company_id,
      person_id: appState.data.person_id,
      user_id: appState.data.user_id,
      iban: data.iban,
      locale,
      base_url: baseUrl,
      user_email: data.email,
      payment_type_id: 2,
      member_category: 'individual',
    };

    let validateBulletinResponse = null;

    try {
      validateBulletinResponse = await validateBulletin(validateBulletinData);
    } catch (e) {
      setLoading(false);
      return false;
    }

    navigate(`/${locale}/upload?code=${validateBulletinResponse.result}`);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const countryIdentifier = config.zones.find(
      (zone) => zone.id === appState.zone,
    );
    getCountries().then((data) => {
      const countriesData = data.map((c) => ({
        id: c.id,
        label: c.name,
      }));
      setCountries(countriesData);
      if (!countryIdentifier) return false;
      const defaultCountry = countriesData.find(
        (c) => c.id === countryIdentifier.isoCode,
      );
      setValue('country', defaultCountry);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    setProvinces([]);
    const configuredZone = config.zones.find(
      (zone) => zone.isoCode === country?.id,
    );
    if (configuredZone && configuredZone.provinceRequired) {
      setLoadingProvinces(true);
      getProvinces(configuredZone.id).then((provinces) => {
        const provincesData = provinces.map((p) => ({
          label: p.name,
          id: p.id,
          postal_code: p.postal_code,
        }));
        setProvinces(provincesData);
        setProvinceRequired(true);
        setLoadingProvinces(false);
      });
    } else {
      setProvinceRequired(false);
    }
  }, [country]);

  useEffect(() => {
    if (!province) return;
    const selectedProvince = provinces.find((item) => item.id === province.id);
    if (!selectedProvince) return;
    setPostalCodePrefix(selectedProvince.postal_code);
  }, [province]);

  useEffect(() => {
    if (iban) {
      setValue('iban', iban.trim());
    }
  }, [iban]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Input type="hidden" {...register('email')} name="email" />
        <Form.Input type="hidden" {...register('zone_id')} name="zone_id" />
        <Form.Input type="hidden" {...register('lead_id')} name="lead_id" />
        <Form.Input type="hidden" {...register('phone')} name="phone" />
        <Form.Input
          type="hidden"
          {...register('country_identifier')}
          name="country_identifier"
        />
        <StepContainer>
          <Card sm={{ span: 10, offset: 1 }}>
            <CardHeader>{t('form.step3.header')}</CardHeader>
            <Banner>{t('form.step3.notice')}</Banner>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Form.SectionTitle>
                  {t('form.step3.section1.title')}
                </Form.SectionTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CompanyDataFields.CompanyName
                  errors={errors}
                  disabled={true}
                  {...register('company_name')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyDataFields.CIF
                  errors={errors}
                  disabled={true}
                  {...register('cif')}
                />
              </Grid>
              <Grid item xs={12}>
                <CompanyDataFields.Address
                  errors={errors}
                  disabled={loading}
                  {...register('address')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('country')}
                  name="country"
                />
                <CompanyDataFields.Country
                  errors={errors}
                  name="countries"
                  locale={locale}
                  value={country}
                  disabled={loading}
                  options={countries}
                  onChange={(event, selectedOption) => {
                    setValue('country', selectedOption);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('province')}
                  name="province"
                />
                {provinceRequired && (
                  <CompanyDataFields.Province
                    errors={errors}
                    name="provinces"
                    locale={locale}
                    disabled={loading || loadingProvinces}
                    options={provinces}
                    onChange={(event, selectedOption) => {
                      setValue('province', selectedOption);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyDataFields.City
                  errors={errors}
                  disabled={loading}
                  {...register('city')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyDataFields.PostalCode
                  errors={errors}
                  disabled={loading}
                  {...register('postal_code')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PriceBlock
                  title={t('form.step3.price.title')}
                  text={t('form.step3.price.text')}
                  price={300}
                  currency="€"
                  priceSubtext={t('form.step3.price.subText')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Form.SectionTitle>
                  {t('form.step3.section2.title')}
                </Form.SectionTitle>
              </Grid>
              <Grid item xs={12}>
                <CompanyDataFields.IBAN
                  errors={errors}
                  disabled={loading}
                  {...register('iban')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FeedbackText>{t('companyDataCard.text')}</FeedbackText>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {errors.apiError && (
                <Grid item xs={12}>
                  <Notice color="red">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: errors.apiError?.message,
                      }}
                    ></span>
                  </Notice>
                </Grid>
              )}
              <Grid item xs={12}>
                <Form.Button disabled={loading}>
                  {t('form.step3.button')}
                  {loading && <Form.Loader />}
                </Form.Button>
              </Grid>
            </Grid>
          </Card>
          <Card sm={{ span: 10, offset: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <HelpCTA />
              </Grid>
            </Grid>
          </Card>
        </StepContainer>
      </Form>
    </FormProvider>
  );
};

export default CompanyDataCard;
