import ReCaptchaV2 from 'react-google-recaptcha';
import { forwardRef } from 'react';
import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const ReCaptcha = forwardRef((props, ref) => {
  const { style, ...other } = props;
  return (
    <div className={styles.recaptcha} style={style}>
      <ReCaptchaV2 {...other} ref={ref} />
    </div>
  );
});

ReCaptcha.propTypes = {
  style: PropTypes.object
};

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;
