import { useTranslation } from 'react-i18next';

import envelope from 'assets/icons/mail.svg';
import Icon from 'UI/atoms/Icon';
import Card from 'UI/molecules/Card';
import Title from 'UI/atoms/Title';
import Notice from 'UI/atoms/Notice';

const PasswordSent = () => {
  const { t } = useTranslation('passwordSentCard');

  return (
    <Card sm={{ span: 10, offset: 1 }}>
      <Icon src={envelope} />
      <Title>{t('title')}</Title>
      <Notice color="blue">{t('text')}</Notice>
    </Card>
  );
};

export default PasswordSent;
