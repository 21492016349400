import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const CTAPhone = ({ children }) => <p className={styles.phone}>{children}</p>;

CTAPhone.propTypes = {
  children: PropTypes.node,
};

export default CTAPhone;
