import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const OtherWorkPositionField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_other_work_position">
        {t('form.step2.otherWorkPosition.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.otherWorkPosition}
          {...other}
          id="form_other_work_position"
          name="otherWorkPosition"
          ref={ref}
          placeholder={t('form.step2.otherWorkPosition.placeholder')}
        />
        {errors.otherWorkPosition && (
          <Form.Error>{errors.otherWorkPosition?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

OtherWorkPositionField.displayName = 'OtherWorkPositionField';

OtherWorkPositionField.propTypes = {
  errors: PropTypes.object,
};

export default OtherWorkPositionField;
