import axios from 'axios';

import toCamelCase from 'utils/toCamelCase';

export const getInterestAreas = async (lang) => {
  const { data } = await axios.get('/interest-areas', { params: { lang } });

  return data
    .map((interestArea) => ({
      id: interestArea.id,
      name: interestArea.name,
    }))
    .sort((a, b) => toCamelCase(a.name).localeCompare(toCamelCase(b.name)));
};
