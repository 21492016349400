import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Title = ({ children }) => <p className={styles.title}>{children}</p>;

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
