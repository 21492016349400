import initialState from './initialState';
import { RESET, SET_ZONE, SET_STEP } from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case RESET: {
      return initialState;
    }

    case SET_ZONE: {
      return {
        ...state,
        zone: action.payload,
      };
    }

    case SET_STEP: {
      return {
        ...state,
        step: action.payload.step,
        ...action.payload.data,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
