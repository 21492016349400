import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CheckConditionsField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Control>
        <Form.Checkbox
          error={errors.conditions}
          htmlFor="form_conditions"
          id="form_conditions"
          name="conditions"
          {...other}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('form.step2.conditions.label'),
            }}
          ></span>
        </Form.Checkbox>
        {errors.conditions && (
          <Form.Error>{errors.conditions?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

CheckConditionsField.displayName = 'CheckConditionsField';

CheckConditionsField.propTypes = {
  errors: PropTypes.object,
};

export default CheckConditionsField;
