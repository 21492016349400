import { useState, forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { pt, fr, es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './styles.module.scss';
import calendar from 'assets/icons/calendar.svg';
import { getMonth, getYear, subYears } from 'date-fns';

const InputCalendar = forwardRef((props, ref) => {
  const { locale, ...other } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { control } = useFormContext();

  let currentLocale = es;

  switch (locale) {
    case 'pt':
      currentLocale = pt;
      break;
    case 'fr':
      currentLocale = fr;
      break;
    case 'es':
    default:
      currentLocale = es;
  }

  const calendarIcon = () => <img src={calendar} />;
  const currentDate = new Date();
  const referenceDate = new Date(
    getYear(currentDate),
    getMonth(currentDate),
    currentDate.getDate(),
  );
  const maxDate = subYears(referenceDate, 50);
  const minDate = subYears(referenceDate, 90);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={currentLocale}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onChange={(e, data) => onChange(data)}
            label={props.label}
            className={styles.datePicker}
            ref={ref}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={true}
            value={value}
            disableHighlightToday={true}
            // readOnly={true}
            closeOnSelect={true}
            {...other}
            slots={{
              openPickerIcon: calendarIcon,
            }}
            slotProps={{
              inputAdornment: {
                classes: {
                  root: styles.endAdornment,
                },
              },
              textField: {
                onClick: () => setIsOpen(true),
                InputProps: {
                  classes: {
                    root: styles.root,
                    input: styles.input,
                    notchedOutline: styles.notchedOutline,
                  },
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
});

InputCalendar.displayName = 'InputCalendar';

InputCalendar.propTypes = {
  label: PropTypes.string,
  locale: PropTypes.string,
};

export default InputCalendar;
