import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Banner = (props) => {
  const { children } = props;

  return (
    <div className={styles.banner}>
      <span dangerouslySetInnerHTML={{ __html: children }}></span>
    </div>
  );
};

Banner.defaultProps = {
  color: 'transparent',
};

Banner.propTypes = {
  children: PropTypes.node,
};

export default Banner;
