import PropTypes from 'prop-types';

import Address from 'UI/atoms/Address';

import styles from './styles.module.scss';

const AddressList = ({ addresses }) => {
  if (addresses.length === 0) return '';

  return (
    <div className={styles.list}>
      {addresses.map((item, index) => (
        <div key={index} className={styles.item}>
          <Address address={item}></Address>
        </div>
      ))}
    </div>
  );
};

AddressList.propTypes = {
  addresses: PropTypes.array,
};

export default AddressList;
