import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const PriceBlock = ({ title, text, price, currency, priceSubtext }) => (
  <div className={styles.wrapper}>
    <div className={styles.leftColumn}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
    <div className={styles.rightColumn}>
      <div className={styles.price}>
        {price}
        <span className={styles.currency}>{currency}</span>
      </div>
      <div className={styles.priceSubtext}>{priceSubtext}</div>
    </div>
  </div>
);

PriceBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  priceSubtext: PropTypes.string,
};

export default PriceBlock;
