import { useTranslation } from 'react-i18next';

import CTA from 'UI/molecules/CTA';

const HelpCTA = () => {
  const { t } = useTranslation('helpCTA');

  return (
    <>
      <CTA>
        <CTA.Title>{t('helpCTA.title')}</CTA.Title>
        <CTA.Subtitle>{t('helpCTA.subtitle')}</CTA.Subtitle>
        <CTA.Phone>{t('helpCTA.phone')}</CTA.Phone>
      </CTA>
    </>
  );
};

export default HelpCTA;
