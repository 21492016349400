import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import Card from 'UI/molecules/Card';
import StepContainer from 'UI/atoms/StepContainer';
import HelpCTA from 'components/HelpCTA';
import Icon from 'UI/atoms/Icon';

import mailIcon from 'assets/icons/mail.svg';
import FeedbackTitle from 'UI/atoms/FeedbackTitle';
import Notice from 'UI/atoms/Notice';
import FeedbackText from 'UI/atoms/FeedbackText';

const AwaitingValidationCard = () => {
  const { t } = useTranslation('awaitingValidationCard');

  return (
    <StepContainer>
      <Card sm={{ span: 10, offset: 1 }}>
        <Icon src={mailIcon} />
        <FeedbackTitle>{t('awaitingValidationCard.title')}</FeedbackTitle>
        <Notice color="blue">
          <div
            dangerouslySetInnerHTML={{
              __html: t('awaitingValidationCard.notice'),
            }}
          ></div>
        </Notice>
        <FeedbackText>{t('awaitingValidationCard.text')}</FeedbackText>
      </Card>
      <Card sm={{ span: 10, offset: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HelpCTA />
          </Grid>
        </Grid>
      </Card>
    </StepContainer>
  );
};

export default AwaitingValidationCard;
