import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Notice = (props) => {
  const { arrow, children } = props;

  return (
    <div
      className={`${styles.notice} ${styles[`notice--${props.color}`]} ${arrow ? styles.arrow : ''}`}
    >
      {children}
    </div>
  );
};

Notice.defaultProps = {
  color: 'transparent',
};

Notice.propTypes = {
  color: PropTypes.string,
  arrow: PropTypes.bool,
  children: PropTypes.node,
};

export default Notice;
