import axios from 'axios';

import toCamelCase from 'utils/toCamelCase';

export const getCountries = async (zone) => {
  const { data } = await axios.get('/countries');

  return data
    .map((country) => ({
      id: country.id,
      name: country.name,
    }))
    .sort((a, b) =>
      toCamelCase(a.name.toLowerCase()).localeCompare(
        toCamelCase(b.name.toLowerCase()),
      ),
    );
};
