import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';

import CardTitle from '../CardTitle';
import styles from './styles.module.scss';

const CardHeader = ({ children }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item xs={12} className={styles.title}>
      <CardTitle>
        <span dangerouslySetInnerHTML={{ __html: children }}></span>
      </CardTitle>
    </Grid>
  </Grid>
);

CardHeader.propTypes = {
  children: PropTypes.node,
};

export default CardHeader;
