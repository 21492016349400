import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const DepartmentField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, setValue, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_department">
        {t('form.step2.department.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Autocomplete
          id="form_department"
          error={errors.department}
          ref={ref}
          placeholder={t('form.step2.department.placeholder')}
          {...other}
        />
        {errors.department && (
          <Form.Error>{errors.department?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

DepartmentField.displayName = 'DepartmentField';

DepartmentField.propTypes = {
  setValue: PropTypes.func,
  errors: PropTypes.object,
};

export default DepartmentField;
