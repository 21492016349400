import RegisterFinishedCard from 'components/RegisterFinishedCard';

const Step5 = () => {
  return (
    <>
      <RegisterFinishedCard />
    </>
  );
};

export default Step5;
