import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const CTATitle = ({ children }) => <p className={styles.title}>{children}</p>;

CTATitle.propTypes = {
  children: PropTypes.node,
};

export default CTATitle;
