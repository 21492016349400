import { Grid } from '@mui/material';

import Icon from '../Icon';
import logo from 'assets/images/logo-apd-genius.png';

import styles from './styles.module.scss';

const Header = () => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item xs={12} className={styles.logo}>
      <Icon src={logo}></Icon>
    </Grid>
  </Grid>
);

export default Header;
