// Pages
import test from './pages/test.json';

// Components
import awaitingValidationCard from './components/awaitingValidationCard.json';
import banner from './components/banner.json';
import checkEmailCard from './components/checkEmailCard.json';
import companyDataCard from './components/companyDataCard.json';
import footer from './components/footer.json';
import form from './components/form.json';
import header from './components/header.json';
import helpCTA from './components/helpCTA.json';
import passwordSentCard from './components/passwordSentCard.json';
import recoverPasswordCard from './components/recoverPasswordCard.json';
import registerFinishedCard from './components/registerFinishedCard.json';
import uploadDocumentCard from './components/uploadDocumentCard.json';
import userDataCard from './components/userDataCard.json';
import userValidationCard from './components/userValidationCard.json';

const translation = {
  // Pages
  test,

  // Components
  awaitingValidationCard,
  banner,
  checkEmailCard,
  companyDataCard,
  footer,
  form,
  header,
  helpCTA,
  passwordSentCard,
  recoverPasswordCard,
  registerFinishedCard,
  uploadDocumentCard,
  userDataCard,
  userValidationCard,
};

export default translation;
