import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';

const BirthDateField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_birth_date">
        {t('form.step2.birthDate.label')} *
      </Form.Label>
      <Form.Control>
        <Form.DatePicker
          id="form_birth_date"
          name="birthDate"
          ref={ref}
          {...other}
          placeholder={t('form.step2.birthDate.placeholder')}
        />
        {errors.birthDate && (
          <Form.Error>{errors.birthDate?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

BirthDateField.displayName = 'BirthDateField';

BirthDateField.propTypes = {
  errors: PropTypes.object,
};

export default BirthDateField;
