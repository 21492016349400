import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const LinksList = ({ links }) => {
  if (links.length === 0) return '';

  return (
    <ul className={styles.list}>
      {links.map((item, index) => (
        <li className={styles.item} key={index}>
          <a
            href={item.link}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

LinksList.propTypes = {
  links: PropTypes.array,
};

export default LinksList;
