import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CheckboxGroup = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>{children}</div>
  </div>
);

CheckboxGroup.propTypes = {
  children: PropTypes.node,
};

export default CheckboxGroup;
