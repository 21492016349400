import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Card = (props) => {
  const { color, children, type } = props;

  return (
    <div
      className={`${styles.wrapper} ${styles[`wrapper--${color}`]} ${type ? `${styles[`wrapper--${type}`]}` : ''}`}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Card.defaultProps = {
  color: 'white',
};

Card.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
