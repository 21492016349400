import { useEffect } from 'react';
import { useMatch, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import TagManager from 'react-gtm-module';

import Footer from 'components/Footer';
import i18n from 'translations';
import changeLanguage from 'translations/changeLanguage';

import styles from './App.module.scss';
import Header from 'UI/atoms/Header';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);

const lang = i18n.language;

function App() {
  const match = useMatch('/:locale/*');
  const navigate = useNavigate();
  const { t } = useTranslation('header');

  useEffect(() => {
    if (!match) {
      return navigate('/es/');
    }
    if (match && lang !== match.params.locale) {
      changeLanguage(match.params.locale);
    }
  }, [match]);

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{t('meta.title')}</title>
      </Helmet>
      <div className={styles.content}>
        <Header />
        {!match && <p>Default page</p>}
        {<Outlet />}
        <Footer />
      </div>
    </>
  );
}

export default App;
