export const RESET = 'RESET';
export const SET_DATA = 'SET_DATA';
export const SET_ZONE = 'SET_ZONE';
export const SET_STEP = 'SET_STEP';
export const STEP_INSERT_USER_DATA = 'STEP_INSERT_USER_DATA';
export const STEP_AWAITING_VALIDATION = 'STEP_AWAITING_VALIDATION';
export const STEP_CHECK_EMAIL = 'STEP_CHECK_EMAIL';
export const STEP_ALREADY_REGISTERED = 'STEP_ALREADY_REGISTERED';
export const STEP_VALIDATION = 'STEP_VALIDATION';
export const STEP_COMPANY_DATA = 'STEP_COMPANY_DATA';
export const STEP_UPLOAD_DOCUMENT = 'STEP_UPLOAD_DOCUMENT';
