import { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';

const WorkPositionField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_work_position">
        {t('form.step2.workPosition.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Autocomplete
          id="form_work_position"
          error={errors.workPosition}
          ref={ref}
          placeholder={t('form.step2.workPosition.placeholder')}
          {...other}
        />
        {errors.workPosition && (
          <Form.Error>{errors.workPosition?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

WorkPositionField.displayName = 'WorkPositionField';

WorkPositionField.propTypes = {
  errors: PropTypes.object,
};

export default WorkPositionField;
