import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useAppStore } from 'context/AppProvider/hooks';
import HelpCTA from 'components/HelpCTA';
import Card from 'UI/molecules/Card';
import StepContainer from 'UI/atoms/StepContainer';
import Icon from 'UI/atoms/Icon';
import { validateUser } from 'services/validateUser';
import Loader from 'UI/atoms/Loader/index';
import FeedbackTitle from 'UI/atoms/FeedbackTitle';
import FeedbackText from 'UI/atoms/FeedbackText';

import warningIcon from 'assets/icons/warning.png';
import { SET_STEP, STEP_COMPANY_DATA } from 'context/AppProvider/constants';

const UserValidationCard = () => {
  const { t } = useTranslation('userValidationCard');
  const { locale } = useParams();
  const [, appDispatch] = useAppStore();
  const [registerValid, setRegisterValid] = useState(true);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const validateCode = async () => {
    const code = searchParams.get('code');

    setRegisterValid(true);
    const jsonInfo = JSON.parse(atob(code));

    if (
      !jsonInfo.cif ||
      !jsonInfo.code ||
      !jsonInfo.company_id ||
      !jsonInfo.company_name ||
      !jsonInfo.country_identifier ||
      !jsonInfo.email ||
      !jsonInfo.lead_id ||
      !jsonInfo.person_id ||
      !jsonInfo.phone ||
      !jsonInfo.zone_id
    ) {
      setRegisterValid(false);
      return false;
    }
    appDispatch({
      type: SET_STEP,
      payload: {
        step: STEP_COMPANY_DATA,
        data: {
          zone: jsonInfo.zone_id,
          data: jsonInfo,
        },
      },
    });

    await validateUser({
      code: jsonInfo.code,
      companyId: jsonInfo.company_id,
      origin: 'BADGenius',
    })
      .then((results) => {
        navigate(`/${locale}/company-data`);
      })
      .catch(() => {
        setRegisterValid(false);
      });
  };

  useEffect(() => {
    validateCode();
  }, []);

  return (
    <StepContainer>
      {!registerValid && (
        <>
          <Card sm={{ span: 10, offset: 1 }}>
            <Icon src={warningIcon} />
            <FeedbackTitle>{t('already_completed.title')}</FeedbackTitle>
            <FeedbackText>{t('already_completed.text')}</FeedbackText>
          </Card>
          <Card sm={{ span: 10, offset: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <HelpCTA />
              </Grid>
            </Grid>
          </Card>
        </>
      )}
      {registerValid && (
        <>
          <Card sm={{ span: 10, offset: 1 }}>
            <Loader position="relative" size="big" color="green" />
            <FeedbackText>{t('loading_validation')}</FeedbackText>
          </Card>
        </>
      )}
    </StepContainer>
  );
};

export default UserValidationCard;
