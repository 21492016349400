import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import pt from 'react-phone-input-2/lang/pt.json';

import styles from './styles.module.scss';

import config from 'data/config.json';

const Phone = forwardRef((props, ref) => {
  const { error, country, inputProps, ...other } = props;

  const i18ns = [
    { language: 'es', data: es },
    { language: 'fr', data: fr },
    { language: 'pt', data: pt },
  ];

  const currentI18n = config.zones.find(
    (zone) => zone.locale === country,
  )?.phoneI18n;
  let localization = es;
  i18ns.map((i18n) => {
    if (i18n.language === currentI18n) {
      localization = i18n.data;
    }
    return true;
  });

  return (
    <PhoneInput
      country={country}
      localization={localization}
      inputClass={`${styles.input} ${error ? styles.error : ''}`}
      buttonClass={`${styles.dropdown} ${error ? styles.dropdownError : ''}`}
      dropdownClass={styles.search}
      countryCodeEditable={false}
      ref={ref}
      placeholder=""
      inputProps={{
        ...inputProps,
      }}
      {...other}
    />
  );
});

Phone.displayName = 'Phone';

Phone.propTypes = {
  error: PropTypes.any,
  locale: PropTypes.string,
  country: PropTypes.string,
  inputProps: PropTypes.object,
};

export default Phone;
