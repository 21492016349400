import UserDataCard from 'components/UserDataCard';

const Step2 = () => {
  return (
    <>
      <UserDataCard />
    </>
  );
};

export default Step2;
