import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const PasswordField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_password">
        {t('form.step2.password.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.password}
          id="form_password"
          name="password"
          type="password"
          ref={ref}
          {...other}
          placeholder={t('form.step2.password.placeholder')}
        />
        {errors.password && <Form.Error>{errors.password?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

PasswordField.displayName = 'PasswordField';

PasswordField.propTypes = {
  errors: PropTypes.object,
};

export default PasswordField;
