import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete as AutocompleteMUI, TextField } from '@mui/material';

import styles from './styles.module.scss';

const Autocomplete = forwardRef(
  (
    {
      name,
      label,
      options,
      renderOption,
      placeholder,
      getOptionLabel,
      containerSize,
      ...props
    },
    ref,
  ) => {
    const { control } = useFormContext();
    const { t } = useTranslation('form');

    return (
      <div
        className={`${styles.autocomplete} ${
          containerSize === 'small' ? styles['autocomplete--small'] : ''
        }`}
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <>
              <AutocompleteMUI
                slotProps={{
                  paper: {
                    className: styles.paper,
                  },
                }}
                ref={ref}
                onChange={(e, data) => onChange(data)}
                value={value}
                ListboxProps={{
                  className: styles.listbox,
                }}
                classes={{
                  root: `${styles.input} ${props.disabled ? styles['input--disabled'] : ''}`,
                  endAdornment: styles.endAdornment,
                  option: styles.option,
                  noOptions: styles.noOptions,
                }}
                clearIcon={null}
                noOptionsText={t('autocomplete.noOptions')}
                options={options}
                renderOption={renderOption}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: styles.textFieldRoot,
                        input: styles.textFieldInput,
                        notchedOutline: styles.notchedOutline,
                      },
                    }}
                    label={label}
                    InputLabelProps={{
                      classes: {
                        root: styles.label,
                        shrink: styles['label--shrink'],
                      },
                    }}
                  />
                )}
                {...props}
              />
            </>
          )}
        />
      </div>
    );
  },
);

Autocomplete.displayName = 'Autocomplete';

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  containerSize: PropTypes.string,
  options: PropTypes.array,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.any,
  disabled: PropTypes.bool,
};
export default Autocomplete;
