import { PropTypes } from 'prop-types';

import CTAPhone from 'UI/atoms/CTAPhone';
import CTASubtitle from 'UI/atoms/CTASubtitle';
import CTATitle from 'UI/atoms/CTATitle';

import styles from './styles.module.scss';

const CTA = (props) => {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
};

CTA.Title = CTATitle;
CTA.Subtitle = CTASubtitle;
CTA.Phone = CTAPhone;

CTA.propTypes = {
  children: PropTypes.node,
};

export default CTA;
