import { useEffect, useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';

import HelpCTA from 'components/HelpCTA';
import { useAppStore } from 'context/AppProvider/hooks';
import { SET_ZONE } from 'context/AppProvider/constants';
import config from 'data/config.json';
import { getProvinces } from 'services/getProvinces';
import { getCompanyDepartments } from 'services/getCompanyDepartments';
import { getWorkPositions } from 'services/getWorkPositions';
import { createUser } from 'services/createUser';
import Notice from 'UI/atoms/Notice';
import Form from 'UI/molecules/Form';
import Card from 'UI/molecules/Card';
import CardHeader from 'UI/atoms/CardHeader';
import Banner from 'UI/atoms/Banner';
import StepContainer from 'UI/atoms/StepContainer';

import UserDataFields from './fields';
import validationSchema from './validationSchema';
import { format } from 'date-fns';

const UserDataCard = () => {
  const navigate = useNavigate();
  const { locale } = useParams();
  const { t, i18n } = useTranslation(['userDataCard', 'form']);
  const [appState, appDispatch] = useAppStore();

  const [loading, setLoading] = useState(true);
  const [loadingWorkPositions, setLoadingWorkPositions] = useState(false);
  const [checkingId, setCheckingId] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [workPositions, setWorkPositions] = useState([]);
  const [validationSent, setValidationSent] = useState(false);

  const workPositionInputRef = useRef();
  const baseUrl = `${window.location.protocol}//${window.location.host}/${locale}/validation`;
  const zone = config.zones.find((zone) => zone.locale === i18n.language);
  const countryIdentifier = config.zones.find(
    (zone) => zone.id === appState.zone,
  )?.isoCode;

  const schema = validationSchema(zone, countryIdentifier, setCheckingId);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      country_identifier: countryIdentifier,
      zone_id: appState.zone,
      email: appState.data.email,
      birthDate: null,
      name: '',
      surname1: '',
      surname2: '',
      legal_identifier: '',
      phone: '',
      province: null,
      department: null,
      workPosition: null,
      workPositions: null,
      password: '',
      password_confirmation: '',
      communication_acceptance: false,
      conditions: false,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = methods;

  const workPosition = watch('workPosition');
  const department = watch('department');
  const password = watch('password');
  const passwordConfirmation = watch('password_confirmation');

  const onSubmit = async (data) => {
    const birthDate = format(data.birthDate, 'yyyyMMdd');

    const userData = {
      zone_id: data.zone_id,
      email: data.email,
      name: data.name,
      surname1: data.surname1,
      surname2: data.surname2,
      legal_identifier: data.legal_identifier,
      country_identifier: data.country_identifier,
      province: data.province,
      phone: `+${data.phone}`,
      department: parseInt(data.department, 10),
      position: parseInt(data.workPosition, 10),
      position_custom: data.otherWorkPosition,
      locale,
      birth_date: birthDate,
      origin: 'BADGenius',
      password: data.password,
      communication_acceptance: data.communication_acceptance,
      base_url: baseUrl,
    };

    setLoading(true);
    createUser(userData)
      .then(() => {
        setValidationSent(true);
      })
      .catch(() => {
        setError('apiError', { message: t('api.error', { ns: 'form' }) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePhone = (value, country) => {
    setValue('phone', value);
    setValue('dialCode', country);
  };

  useEffect(() => {
    setLoading(true);
    const zone = config.zones.find((zone) => zone.locale === 'es');
    appDispatch({ type: SET_ZONE, payload: zone.id });
    getProvinces(appState.zone).then((provinces) => {
      setProvinces(provinces);
    });
    getCompanyDepartments(countryIdentifier).then((departments) => {
      setDepartments(departments);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    setValue('workPositions', null);
    setValue('workPosition', null);
    setValue('otherWorkPosition', '');

    if (!department) {
      return;
    }

    setLoadingWorkPositions(true);

    getWorkPositions(department, countryIdentifier).then((newWorkPositions) => {
      newWorkPositions.push({
        id: -1,
        name: t('form.step2.workPosition.other'),
      });
      setWorkPositions(newWorkPositions);
      setLoadingWorkPositions(false);
    });
  }, [department]);

  useEffect(() => {
    if (workPosition !== -1) {
      setValue('otherWorkPosition', '');
    }
  }, [workPosition]);

  useEffect(() => {
    if (validationSent) {
      navigate(`/${locale}/awaiting-validation`);
    }
  }, [validationSent]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StepContainer>
          <Card sm={{ span: 10, offset: 1 }}>
            <CardHeader>{t('form.step2.header')}</CardHeader>
            <Banner>{t('form.step2.notice')}</Banner>
            <Form.SectionTitle>
              {t('form.step2.section.title')}
            </Form.SectionTitle>
            <Form.Input type="hidden" {...register('zone_id')} name="zone_id" />
            <Form.Input
              type="hidden"
              {...register('country_identifier')}
              name="country_identifier"
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UserDataFields.Email {...register('email')} />
              </Grid>
              <Grid item xs={12}>
                <UserDataFields.Name
                  errors={errors}
                  disabled={loading}
                  {...register('name')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <UserDataFields.FirstSurname
                  errors={errors}
                  disabled={loading}
                  {...register('surname1')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <UserDataFields.SecondSurname
                  errors={errors}
                  disabled={loading}
                  {...register('surname2')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <UserDataFields.LegalIdentifier
                  errors={errors}
                  disabled={loading}
                  {...register('legal_identifier')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('dialCode')}
                  name="dialCode"
                />
                <UserDataFields.Phone
                  errors={errors}
                  disabled={loading}
                  country={locale}
                  onChange={updatePhone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('birthDate')}
                  name="birthDate"
                />
                <UserDataFields.BirthDate
                  errors={errors}
                  locale={locale}
                  disabled={loading}
                  onChange={(value) => {
                    setValue('birthDate', value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('province')}
                  name="province"
                />
                <UserDataFields.Province
                  errors={errors}
                  name="provinces"
                  locale={locale}
                  disabled={loading}
                  options={provinces.map((option) => ({
                    label: option.name,
                    id: option.id,
                  }))}
                  onChange={(event, selectedOption) => {
                    setValue('province', selectedOption.id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('department')}
                  name="department"
                />
                <UserDataFields.Department
                  errors={errors}
                  name="departments"
                  locale={locale}
                  disabled={loading}
                  options={departments.map((option) => ({
                    label: option.name,
                    id: option.id,
                  }))}
                  onChange={(event, selectedOption) => {
                    setValue('department', selectedOption.id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Form.Input
                  type="hidden"
                  {...register('workPosition')}
                  name="work_position"
                />
                <UserDataFields.WorkPosition
                  errors={errors}
                  name="workPositions"
                  locale={locale}
                  disabled={loading || loadingWorkPositions || !department}
                  ref={workPositionInputRef}
                  options={workPositions.map((option) => ({
                    label: option.name,
                    id: option.id,
                  }))}
                  onChange={(event, selectedOption) => {
                    setValue('workPositions', selectedOption);
                    setValue('workPosition', selectedOption.id);
                  }}
                />
              </Grid>

              {workPosition === -1 && (
                <Grid item xs={12}>
                  <UserDataFields.OtherWorkPosition
                    errors={errors}
                    disabled={loading}
                    {...register('otherWorkPosition')}
                  />
                </Grid>
              )}
            </Grid>
          </Card>

          <Card color="light-gray" sm={{ span: 10, offset: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <UserDataFields.Password
                  errors={errors}
                  disabled={loading}
                  {...register('password')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <UserDataFields.PasswordConfirmation
                  errors={errors}
                  disabled={loading}
                  {...register('password_confirmation')}
                />
              </Grid>
              <Grid item xs={12}>
                <UserDataFields.PasswordCheck
                  value={password}
                  valueAgain={passwordConfirmation}
                />
              </Grid>
            </Grid>
          </Card>
          <Card sm={{ span: 10, offset: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UserDataFields.CheckCommunicationsAcceptance
                  errors={errors}
                  disabled={loading}
                  {...register('communication_acceptance')}
                />
              </Grid>
              <Grid item xs={12}>
                <UserDataFields.CheckConditions
                  errors={errors}
                  disabled={loading}
                  {...register('conditions')}
                />
              </Grid>
              {errors.apiError && (
                <Grid item xs={12}>
                  <Notice color="red">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: errors.apiError?.message,
                      }}
                    ></span>
                  </Notice>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Form.Button disabled={loading || checkingId}>
                  {t('form.step2.button')}
                  {(loading || checkingId) && <Form.Loader />}
                </Form.Button>
              </Grid>
            </Grid>
          </Card>
          <Card sm={{ span: 10, offset: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HelpCTA />
              </Grid>
            </Grid>
          </Card>
        </StepContainer>
      </Form>
    </FormProvider>
  );
};

export default UserDataCard;
