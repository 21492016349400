import { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppStore } from 'context/AppProvider/hooks';
import { Grid } from '@mui/material';

import config from 'data/config.json';

import Notice from 'UI/atoms/Notice';
import Form from 'UI/molecules/Form';
import Card from 'UI/molecules/Card';
import ReCaptcha from 'UI/atoms/ReCaptcha';
import { validateEmail } from 'services/validateEmail';
import {
  SET_ZONE,
  SET_STEP,
  STEP_INSERT_USER_DATA,
  STEP_ALREADY_REGISTERED,
} from 'context/AppProvider/constants';
import StepContainer from 'UI/atoms/StepContainer';
import CardHeader from 'UI/atoms/CardHeader';
import Banner from 'UI/atoms/Banner';
import validationSchema from './validationSchema';

const CheckEmailCard = () => {
  const { t } = useTranslation('checkEmailCard');
  const [validRecaptcha, setValidRecaptcha] = useState(false);
  const [appState, appDispatch] = useAppStore();
  const [loading, setLoading] = useState(false);
  const [mailIsValid, setMailIsValid] = useState(true);
  const recaptchaRef = useRef();

  const schema = validationSchema(mailIsValid);

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const emailField = watch('email');

  useEffect(() => {
    setValue('email', emailField.replace(/ /gi, ''));
    setMailIsValid(true);
  }, [emailField]);

  useEffect(() => {
    window.sessionStorage.removeItem('token');
    const zone = config.zones.find((zone) => zone.locale === 'es');
    appDispatch({ type: SET_ZONE, payload: zone.id });
  }, []);

  useEffect(() => {
    const validateEmailField = async () => {
      await trigger();
    };
    if (!mailIsValid) {
      validateEmailField();
    }
  }, [mailIsValid]);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = recaptchaRef.current.getValue();
    validateEmail({
      zone_id: appState.zone,
      email: data.email,
      recaptcha: token,
      origin: 'BADGenius',
    })
      .then((result) => {
        if (result.token) {
          window.sessionStorage.setItem('token', result.token);
        }
        if (result.isUsed) {
          appDispatch({
            type: SET_STEP,
            payload: { step: STEP_ALREADY_REGISTERED, data: { data } },
          });
          return;
        }
        if (!result.isValid) {
          recaptchaRef.current.reset();
          setMailIsValid(false);
          return;
        }
        appDispatch({
          type: SET_STEP,
          payload: { step: STEP_INSERT_USER_DATA, data: { data } },
        });
      })
      .catch(() => {
        setError('apiError', { message: t('api.error') });
        recaptchaRef.current.reset();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      setValidRecaptcha(true);
    }
  };

  const onExpiredRecaptcha = () => {
    setValidRecaptcha(false);
  };

  return (
    <StepContainer>
      <Card sm={{ span: 10, offset: 1 }}>
        <CardHeader>{t('form.step1.header')}</CardHeader>
        <Banner>{t('form.step1.notice')}</Banner>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.SectionTitle>Datos de usuario</Form.SectionTitle>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Form.Field>
                <Form.Label htmlFor="form_email">
                  {t('form.step1.email.label')}
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    disabled={loading}
                    error={errors.email}
                    id="form_email"
                    name="email"
                    {...register('email')}
                    placeholder={t('form.step1.email.placeholder')}
                  />
                  {errors.email && (
                    <Form.Error>{errors.email?.message}</Form.Error>
                  )}
                </Form.Control>
              </Form.Field>
            </Grid>
            <Grid item xs={12}>
              <ReCaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onChangeRecaptcha}
                onExpired={onExpiredRecaptcha}
                ref={recaptchaRef}
              />
            </Grid>
          </Grid>

          {errors.apiError && (
            <Notice color="red">
              <span
                dangerouslySetInnerHTML={{ __html: errors.apiError?.message }}
              ></span>
            </Notice>
          )}

          <Form.Button disabled={!validRecaptcha || loading}>
            {t('form.step1.button')}
            {loading && <Form.Loader />}
          </Form.Button>
        </Form>
      </Card>
    </StepContainer>
  );
};

export default CheckEmailCard;
