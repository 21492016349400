import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';

import styles from './styles.module.scss';

const CustomDialog = ({ children, onClose }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        centered
        onClose={handleClose}
        className={styles.dialog}
        classes={{
          paper: styles.paper,
        }}
      >
        {children}
      </Dialog>
    </>
  );
};

CustomDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default CustomDialog;
