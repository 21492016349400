import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const validationSchema = (mailIsValid) => {
  const { t } = useTranslation(['checkEmailCard', 'form']);

  return Yup.object().shape({
    email: Yup.string()
      .required(t('error.required', { ns: 'form' }))
      .email(t('error.email.invalid', { ns: 'form' }))
      .test(
        'is-mail-valid',
        t('error.email.not-corporative', { ns: 'form' }),
        () => mailIsValid,
      ),
  });
};

export default validationSchema;
