import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Input = forwardRef((props, ref) => {
  const { error, ...other } = props;

  return (
    <input
      className={`${styles.input} ${error ? styles.error : ''}`}
      ref={ref}
      {...other}
    />
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  error: PropTypes.any,
};

export default Input;
