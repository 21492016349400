import { PropTypes } from 'prop-types';

import Autocomplete from 'UI/atoms/Autocomplete';
import Button from 'UI/atoms/Button';
import Checkbox from 'UI/atoms/Checkbox';
import CheckboxGroup from 'UI/atoms/CheckboxGroup';
import CheckboxRow from 'UI/atoms/CheckboxRow';
import Control from 'UI/atoms/Control';
import Error from 'UI/atoms/Error';
import Field from 'UI/atoms/Field';
import Input from 'UI/atoms/Input';
import InputCalendar from 'UI/atoms/InputCalendar';
import InputFile from 'UI/atoms/InputFile';
import Label from 'UI/atoms/Label';
import Legend from 'UI/atoms/Legend';
import Loader from 'UI/atoms/Loader';
import PasswordCheck from 'UI/atoms/PasswordCheck';
import Phone from 'UI/atoms/Phone';
import SectionTitle from 'UI/atoms/SectionTitle';

import styles from './styles.module.scss';

const Form = (props) => (
  <form className={styles.form} {...props}>
    {props.children}
  </form>
);

Form.Autocomplete = Autocomplete;

Form.Button = Button;

Form.Checkbox = Checkbox;

Form.CheckboxGroup = CheckboxGroup;

Form.CheckboxRow = CheckboxRow;

Form.Control = Control;

Form.DatePicker = InputCalendar;

Form.Error = Error;

Form.Field = Field;

Form.Input = Input;

Form.File = InputFile;

Form.Label = Label;

Form.Legend = Legend;

Form.Loader = Loader;

Form.Phone = Phone;

Form.PasswordCheck = PasswordCheck;

Form.SectionTitle = SectionTitle;

Form.propTypes = {
  children: PropTypes.node,
};

export default Form;
