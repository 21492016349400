import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import Card from 'UI/molecules/Card';
import Form from 'UI/molecules/Form';
import StepContainer from 'UI/atoms/StepContainer';
import HelpCTA from 'components/HelpCTA';
import Icon from 'UI/atoms/Icon';
import FeedbackTitle from 'UI/atoms/FeedbackTitle';
import Notice from 'UI/atoms/Notice';

import contractIcon from 'assets/icons/contract.svg';
import uploadDocument from 'services/uploadDocument';

const UploadDocumentCard = () => {
  const { t } = useTranslation(['uploadDocumentCard', 'form']);
  const navigate = useNavigate();
  const { locale } = useParams();
  const [loading, setLoading] = useState(false);
  const [urlDownload, setUrlDownload] = useState(null);
  const [associatedId, setAssociatedId] = useState(null);
  const [codeValid, setCodeValid] = useState(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const validateCode = () => {
    setCodeValid(true);
    const jsonInfo = JSON.parse(atob(code));

    if (
      !jsonInfo.lead_id ||
      !jsonInfo.company_id ||
      !jsonInfo.person_id ||
      !jsonInfo.associated_id ||
      !jsonInfo.file
    ) {
      setCodeValid(false);
      return false;
    }

    setUrlDownload(jsonInfo.file);
    setAssociatedId(jsonInfo.associated_id);
  };

  useEffect(() => {
    validateCode();
  }, []);

  const downloadFile = (urlFile) => {
    if (!urlFile) return false;
    const link = document.createElement('a');
    link.href = urlFile;
    link.setAttribute('download', `APD-Genius.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    downloadFile(urlDownload);
  }, [urlDownload]);

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required(t('error.required', { ns: 'form' })),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      file: null,
    },
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await uploadDocument(associatedId, getValues('file')[0]);
    } catch (e) {
      setLoading(false);
      return false;
    }

    setLoading(false);
    navigate(`/${locale}/register-finished/?code=${code}`);
  };

  return (
    <StepContainer>
      <Card sm={{ span: 10, offset: 1 }}>
        <Icon src={contractIcon} />
        <FeedbackTitle>{t('uploadDocumentCard.title')}</FeedbackTitle>
        <Notice color="blue">
          <div
            dangerouslySetInnerHTML={{
              __html: t('uploadDocumentCard.notice', {
                documentUrl: urlDownload,
              }),
            }}
          ></div>
        </Notice>

        {codeValid && (
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Form.Field>
                    <Form.Label htmlFor="form_file">
                      {t('form.step4.field.label')}
                    </Form.Label>
                    <Form.Control>
                      <Form.File
                        disabled={loading}
                        filename=""
                        register={register}
                        id="form_file"
                        name="file"
                        placeholder={t('form.step4.file.placeholder')}
                      />
                      {errors.file && (
                        <Form.Error>{errors.file?.message}</Form.Error>
                      )}
                    </Form.Control>
                  </Form.Field>
                </Grid>
                <Grid item xs={12}>
                  <Form.Button disabled={loading}>
                    {t('form.step4.button')}
                    {loading && <Form.Loader />}
                  </Form.Button>
                </Grid>
              </Grid>
            </Form>
          </FormProvider>
        )}

        {!codeValid && (
          <>
            <p>Código no válido</p>
          </>
        )}
      </Card>
      <Card sm={{ span: 10, offset: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HelpCTA />
          </Grid>
        </Grid>
      </Card>
    </StepContainer>
  );
};

export default UploadDocumentCard;
