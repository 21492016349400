import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const PostalCodeField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_postal_code">
        {t('form.step3.postalCode.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.postal_code}
          id="form_postal_code"
          name="postal_code"
          ref={ref}
          {...other}
          placeholder={t('form.step3.postalCode.placeholder')}
        />
        {errors.postal_code && (
          <Form.Error>{errors.postal_code?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

PostalCodeField.displayName = 'PostalCodeField';

PostalCodeField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default PostalCodeField;
