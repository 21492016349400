import AwaitingValidationCard from 'components/AwaitingValidationCard';

const Step3 = () => {
  return (
    <>
      <AwaitingValidationCard />
    </>
  );
};

export default Step3;
