import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const LegalIdentifierField = forwardRef((props, ref) => {
  const { t } = useTranslation('userDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_legal_identifier">
        {t('form.step2.legal_identifier.label')} *
      </Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.legal_identifier}
          {...other}
          ref={ref}
          id="form_legal_identifier"
          name="legal_identifier"
          placeholder={t('form.step2.legal_identifier.placeholder')}
        />
        {errors.legal_identifier && (
          <Form.Error>{errors.legal_identifier?.message}</Form.Error>
        )}
      </Form.Control>
    </Form.Field>
  );
});

LegalIdentifierField.displayName = 'LegalIdentifierField';

LegalIdentifierField.propTypes = {
  errors: PropTypes.object,
};

export default LegalIdentifierField;
