import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import Form from 'UI/molecules/Form';

const CIFField = forwardRef((props, ref) => {
  const { t } = useTranslation('companyDataCard');

  const { errors, ...other } = props;

  return (
    <Form.Field>
      <Form.Label htmlFor="form_cif">{t('form.step3.cif.label')} *</Form.Label>
      <Form.Control>
        <Form.Input
          error={errors.cif}
          id="form_cif"
          name="cif"
          ref={ref}
          {...other}
          placeholder={t('form.step3.cif.placeholder')}
        />
        {errors.cif && <Form.Error>{errors.cif?.message}</Form.Error>}
      </Form.Control>
    </Form.Field>
  );
});

CIFField.displayName = 'CIFField';

CIFField.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CIFField;
