import { PropTypes } from 'prop-types';

const Icon = (props) => {
  const { src, ...other } = props;
  return <img src={src} {...other} />;
};

Icon.propTypes = {
  src: PropTypes.string,
};

export default Icon;
