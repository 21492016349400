import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import check from 'assets/icons/check-circle.svg';
import { resetPassword } from 'services/resetPassword';
import { useAppStore } from 'context/AppProvider/hooks';
import Icon from 'UI/atoms/Icon';
import Title from 'UI/atoms/Title';
import Notice from 'UI/atoms/Notice';
import Form from 'UI/molecules/Form';
import Dialog from 'UI/atoms/Dialog';
import Card from 'UI/molecules/Card';
import PasswordSentCard from 'components/PasswordSentCard';
import StepContainer from 'UI/atoms/StepContainer';
import ArrowLink from 'UI/atoms/ArrowLink';

const RecoverPasswordCard = () => {
  const { t } = useTranslation('recoverPasswordCard');
  const [appState] = useAppStore();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    resetPassword({
      zone_id: appState.zone,
      email: appState.data.email,
    })
      .then((result) => {
        if (!result.success) {
          setError('apiError', {
            message: t(`form.recoverPassword.${result.error}`),
          });
          return;
        }
        setShowModal(true);
        reset();
      })
      .catch(() => {
        setError('apiError', { message: t('api.error') });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <StepContainer>
        <Card sm={{ span: 10, offset: 1 }}>
          <Icon src={check}></Icon>
          <Title>{t('already.registered.title')}</Title>
          <Notice color="blue">
            <p>{t('already.registered.text')}</p>
            <ArrowLink arrow="right" href={t('already.registered.link')}>
              <strong>{t('already.registered.linkText')}</strong>
            </ArrowLink>
          </Notice>
        </Card>
        <Card color="gray" sm={{ span: 10, offset: 1 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <Form.Legend>{t('form.recoverPassword.legend')}</Form.Legend>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Form.Field>
                  <Form.Label htmlFor="form_email">
                    {t('form.help.email.label')}
                  </Form.Label>
                  <Form.Control>
                    <Form.Input
                      disabled
                      id="form_email"
                      name="email"
                      defaultValue={appState.data.email}
                      placeholder={t('form.help.email.placeholder')}
                    />
                    {errors.email && (
                      <Form.Error>{errors.email?.message}</Form.Error>
                    )}
                  </Form.Control>
                </Form.Field>
              </Grid>
            </Grid>

            {errors.apiError && (
              <Notice color="red">
                <span
                  dangerouslySetInnerHTML={{ __html: errors.apiError?.message }}
                ></span>
              </Notice>
            )}

            <Form.Button disabled={loading}>
              {t('form.help.button')}
              {loading && <Form.Loader />}
            </Form.Button>
          </Form>
        </Card>

        {showModal && (
          <Dialog onClose={closeModal}>
            <PasswordSentCard />
          </Dialog>
        )}
      </StepContainer>
    </>
  );
};

export default RecoverPasswordCard;
