import AwaitingValidationCard from 'components/AwaitingValidationCard';
import CheckEmailCard from 'components/CheckEmailCard';
import RecoverPasswordCard from 'components/RecoverPasswordCard';
import UserDataCard from 'components/UserDataCard';
import {
  STEP_ALREADY_REGISTERED,
  STEP_AWAITING_VALIDATION,
  STEP_CHECK_EMAIL,
  STEP_INSERT_USER_DATA,
} from 'context/AppProvider/constants';
import { useAppStore } from 'context/AppProvider/hooks';

const Step1 = () => {
  const [appState] = useAppStore();

  return (
    <>
      {appState.step === STEP_CHECK_EMAIL && <CheckEmailCard />}
      {appState.step === STEP_INSERT_USER_DATA && <UserDataCard />}
      {appState.step === STEP_AWAITING_VALIDATION && <AwaitingValidationCard />}
      {appState.step === STEP_ALREADY_REGISTERED && <RecoverPasswordCard />}
    </>
  );
};

export default Step1;
