import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEs from './es/index';

i18n.use(initReactI18next).init({
  lng: 'es',
  fallbackLng: 'es',
  debug: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  returnObjects: true,
  keySeparator: false,

  transSupportBasicHtmlNodes: true,
  resources: {
    es: translationEs,
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;
