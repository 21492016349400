import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const FeedbackTitle = ({ children }) => (
  <p className={styles.title}>{children}</p>
);

FeedbackTitle.propTypes = {
  children: PropTypes.node,
};

export default FeedbackTitle;
