import BirthDate from './BirthDate';
import CheckConditions from './CheckConditions';
import CheckCommunicationsAcceptance from './CheckCommunicationsAcceptance';
import Company from './Company';
import Department from './Department';
import Email from './Email';
import FirstSurname from './FirstSurname';
import LegalIdentifier from './LegalIdentifier';
import Name from './Name';
import OtherWorkPosition from './OtherWorkPosition';
import Password from './Password';
import PasswordCheck from './PasswordCheck';
import PasswordConfirmation from './PasswordConfirmation';
import Phone from './Phone';
import Province from './Province';
import SecondSurname from './SecondSurname';
import WorkPosition from './WorkPosition';

const UserDataFields = () => <></>;

UserDataFields.BirthDate = BirthDate;
UserDataFields.CheckConditions = CheckConditions;
UserDataFields.CheckCommunicationsAcceptance = CheckCommunicationsAcceptance;
UserDataFields.Company = Company;
UserDataFields.Department = Department;
UserDataFields.Email = Email;
UserDataFields.FirstSurname = FirstSurname;
UserDataFields.LegalIdentifier = LegalIdentifier;
UserDataFields.Name = Name;
UserDataFields.OtherWorkPosition = OtherWorkPosition;
UserDataFields.Password = Password;
UserDataFields.PasswordConfirmation = PasswordConfirmation;
UserDataFields.PasswordCheck = PasswordCheck;
UserDataFields.Phone = Phone;
UserDataFields.Province = Province;
UserDataFields.SecondSurname = SecondSurname;
UserDataFields.WorkPosition = WorkPosition;

export default UserDataFields;
