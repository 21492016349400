import axios from 'axios';

export const getInterestZones = async (lang) => {
  const { data } = await axios.get('/interest-zones', { params: { lang } });

  return data
    .map((zone) => ({
      id: zone.id,
      name: zone.name,
    }))
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};
