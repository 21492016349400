import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from 'UI/molecules/Card';
import Form from 'UI/molecules/Form';
import StepContainer from 'UI/atoms/StepContainer';
import HelpCTA from 'components/HelpCTA';
import Icon from 'UI/atoms/Icon';
import FeedbackTitle from 'UI/atoms/FeedbackTitle';
import Notice from 'UI/atoms/Notice';

import checkIcon from 'assets/icons/check-circle.svg';
import { getInterestAreas } from 'services/getInterestAreas';
import ArrowLink from 'UI/atoms/ArrowLink';
import config from 'data/config.json';
import { getCompanySectors } from 'services/getCompanySectors';
import { getInterestZones } from 'services/getInterestZones';
import Loader from 'UI/atoms/Loader';
import { updateUserInterests } from 'services/updateUserInterests';
import { updateUserZones } from 'services/updateUserZones';
import { updateCompanySector } from 'services/updateCompanySector';

import validationSchema from './validationSchema';

const RegisterFinishedCard = () => {
  const { t, i18n } = useTranslation('registerFinishedCard');
  const [loading, setLoading] = useState(true);
  const [processingData, setProcessingData] = useState(false);
  const [codeValid, setCodeValid] = useState(true);
  const [preferencesUpdated, setPreferencesUpdated] = useState(false);
  const [interestAreas, setInterestAreas] = useState([]);
  const [zones, setZones] = useState([]);
  const [companySectors, setCompanySectors] = useState([]);
  const [jsonInfo, setJsonInfo] = useState(null);
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  const validateCode = () => {
    setCodeValid(true);
    const data = JSON.parse(atob(code));

    if (
      !data.lead_id ||
      !data.company_id ||
      !data.person_id ||
      !data.user_id ||
      !data.associated_id ||
      !data.file
    ) {
      setCodeValid(false);
      return false;
    }
    setJsonInfo(data);
  };

  const schema = validationSchema();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      selected_interests: [],
      selected_zones: [],
      sector: null,
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setPreferencesUpdated(false);
    setProcessingData(true);

    const promises = [
      updateUserInterests({
        id: jsonInfo.user_id,
        interests: data.selected_interests,
      }),
      updateUserZones({
        id: jsonInfo.user_id,
        zones: data.selected_zones,
      }),
    ];

    if (data.sector) {
      promises.push(updateCompanySector(jsonInfo.associated_id, data.sector));
    }

    await Promise.all(promises);

    setProcessingData(false);
    setPreferencesUpdated(true);
  };

  const switchInterest = (areaId) => {
    const selectedInterests = getValues('selected_interests');
    const selectedInterestIndex = selectedInterests.findIndex(
      (item) => item === areaId,
    );
    if (selectedInterestIndex >= 0) {
      selectedInterests.splice(selectedInterestIndex, 1);
    } else {
      selectedInterests.push(areaId);
    }
    setValue('selected_interests', selectedInterests);
  };

  const switchZone = (areaId) => {
    const selectedZones = getValues('selected_zones');
    const selectedZoneIndex = selectedZones.findIndex(
      (item) => item === areaId,
    );
    if (selectedZoneIndex >= 0) {
      selectedZones.splice(selectedZoneIndex, 1);
    } else {
      selectedZones.push(areaId);
    }
    setValue('selected_zones', selectedZones);
  };

  const getOptions = async () => {
    const zone = config.zones.find((zone) => zone.locale === i18n.language);

    const [areas, sectors, dataZones] = await Promise.all([
      getInterestAreas(zone.isoCode),
      getCompanySectors(zone.isoCode),
      getInterestZones(zone.isoCode),
    ]);
    setInterestAreas(areas);
    setCompanySectors(sectors);
    setZones(dataZones);
  };

  useEffect(() => {
    validateCode();
    setLoading(true);
    getOptions();
    setLoading(false);
  }, []);

  return (
    <StepContainer>
      <Card sm={{ span: 10, offset: 1 }}>
        <Icon src={checkIcon} />
        <FeedbackTitle>{t('registerFinishedCard.title')}</FeedbackTitle>
        <Notice color="blue">
          <span
            dangerouslySetInnerHTML={{
              __html: t('registerFinishedCard.notice'),
            }}
          ></span>
          <ArrowLink href={t('registerFinishedCard.linkHref')}>
            <strong>{t('registerFinishedCard.linkText')}</strong>
          </ArrowLink>
        </Notice>

        {codeValid && (
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form.SectionTitle>
                    {t('registerFinishedCard.section1.title')}
                  </Form.SectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Form.Input
                    type="hidden"
                    {...register('sector')}
                    name="sector"
                  />
                  <Form.Field>
                    <Form.Control>
                      <Form.Autocomplete
                        id="form_sectors"
                        name="sectors"
                        disabled={loading}
                        placeholder={t(
                          'registerFinishedCard.sectors.placeholder',
                        )}
                        options={companySectors.map((option) => ({
                          label: option.name,
                          id: option.id,
                        }))}
                        onChange={(event, selectedOption) => {
                          setValue('sector', selectedOption.id);
                        }}
                      />
                      {errors.sector && (
                        <Form.Error>{errors.sector?.message}</Form.Error>
                      )}
                    </Form.Control>
                  </Form.Field>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form.SectionTitle>
                    {t('registerFinishedCard.section2.title')}
                  </Form.SectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Form.Input
                    type="hidden"
                    {...register('selected_interests')}
                    name="selected_interests"
                  />
                  <Form.CheckboxGroup>
                    {loading && (
                      <Loader
                        color="green"
                        position="relative"
                        valign="center"
                      />
                    )}
                    {interestAreas.map((area) => (
                      <Form.CheckboxRow
                        onChange={() => switchInterest(area.id)}
                        key={area.id}
                      >
                        {area.name}
                      </Form.CheckboxRow>
                    ))}
                  </Form.CheckboxGroup>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form.SectionTitle>
                    {t('registerFinishedCard.section3.title')}
                  </Form.SectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Form.Input
                    type="hidden"
                    {...register('selected_zones')}
                    name="selected_zones"
                  />
                  <Form.CheckboxGroup>
                    {loading && (
                      <Loader
                        color="green"
                        position="relative"
                        valign="center"
                      />
                    )}
                    {zones.map((zone) => (
                      <Form.CheckboxRow
                        onChange={() => switchZone(zone.id)}
                        key={zone.id}
                      >
                        {zone.name}
                      </Form.CheckboxRow>
                    ))}
                  </Form.CheckboxGroup>
                </Grid>
                {preferencesUpdated && (
                  <Grid item xs={12}>
                    <Notice color="blue">
                      {t('registerFinishedCard.updated.data')}
                    </Notice>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Form.Button disabled={loading || processingData}>
                    {t('registerFinishedCard.button')}
                    {processingData && <Form.Loader />}
                  </Form.Button>
                </Grid>
              </Grid>
            </Form>
          </FormProvider>
        )}

        {!codeValid && (
          <>
            <p>Código no válido</p>
          </>
        )}
      </Card>
      <Card sm={{ span: 10, offset: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HelpCTA />
          </Grid>
        </Grid>
      </Card>
    </StepContainer>
  );
};

export default RegisterFinishedCard;
