import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';

import routes from './routes';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from 'context/AppProvider';

import 'utils/axiosHeaders';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <HelmetProvider>
        <AppProvider>
          <RouterProvider router={router} />
          <ToastContainer />
        </AppProvider>
      </HelmetProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
