import axios from 'axios';

export const associateCompany = async (params) => {
  const { data } = await axios.post(
    '/users/company-association-request',
    params,
  );

  return data;
};
