import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const Loader = (props) => {
  const { position, size, valign, color } = props;
  const className = `${styles.loader} ${styles[`loader--${valign}`]} ${styles[`loader--${position}`]} ${styles[`loader--${size}`]} ${styles[`loader--${color}`]}`;
  return <span className={className}></span>;
};

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  position: PropTypes.string,
  valign: PropTypes.string,
};

export default Loader;
