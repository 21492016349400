import Address from './Address';
import CIF from './CIF';
import City from './City';
import CompanyName from './CompanyName';
import Country from './Country';
import IBAN from './IBAN';
import PostalCode from './PostalCode';
import Province from './Province';

const CompanyDataFields = () => <></>;

CompanyDataFields.Address = Address;
CompanyDataFields.CIF = CIF;
CompanyDataFields.City = City;
CompanyDataFields.CompanyName = CompanyName;
CompanyDataFields.Country = Country;
CompanyDataFields.IBAN = IBAN;
CompanyDataFields.PostalCode = PostalCode;
CompanyDataFields.Province = Province;

export default CompanyDataFields;
